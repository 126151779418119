import React, { useEffect, useState } from "react";
import { JobOrder } from "../../interfaces/job.interface";

interface MapComponentProps {
  job: JobOrder;
}

const MapComponent: React.FC<MapComponentProps> = ({ job }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [distance, setDistance] = useState<string>("");
  const [estimatedTime, setEstimatedTime] = useState<string>("");

  useEffect(() => {
    if (window.google) {
      const mapOptions: google.maps.MapOptions = {
        streetViewControl: false,
        fullscreenControl: true,
        zoom: 8,
        center: {
          lat: parseFloat(job.sourceLatitude as string),
          lng: parseFloat(job.sourceLongitude as string),
        },
        styles: [
          {
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      };

      const mapInstance = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        mapOptions
      );
      setMap(mapInstance);

      const sourcePosition = {
        lat: parseFloat(job.sourceLatitude as string),
        lng: parseFloat(job.sourceLongitude as string),
      };
      const destinationPosition = {
        lat: parseFloat(job.destLatitude as string),
        lng: parseFloat(job.destLongitude as string),
      };

      const createMarker = (
        position: google.maps.LatLngLiteral,
        label: string,
        iconColor: string
      ) => {
        new google.maps.Marker({
          position,
          map: mapInstance,
          label: { text: label, color: "#000" },
          icon: {
            path: "M10.5 0.368164C4.97662 0.368164 0.5 4.84586 0.5 10.3682C0.5 13.4471 1.89154 16.1999 4.07974 18.0348L10.5 24.4562L16.9203 18.0359C19.1085 16.2011 20.5 13.4482 20.5 10.3692C20.5 4.84586 16.0231 0.368164 10.5 0.368164ZM10.5 17.0312C6.99332 17.0312 4.15067 14.1885 4.15067 10.6818C4.15067 7.17624 6.99332 4.33359 10.5 4.33359C14.0067 4.33359 16.8493 7.17624 16.8493 10.6829C16.8493 14.1885 14.0067 17.0312 10.5 17.0312Z",
            fillColor: iconColor,
            fillOpacity: 1.0,
            strokeColor: "#000",
            strokeWeight: 1,
            scale: 1,
            anchor: new google.maps.Point(12, 24),
          },
        });
      };

      createMarker(sourcePosition, job.source, "#cbd2d9");
      createMarker(destinationPosition, job.destination, "#19b5fe");

      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: { strokeColor: "#000", strokeWeight: 4 },
      });
      directionsRenderer.setMap(mapInstance);

      directionsService.route(
        {
          origin: sourcePosition,
          destination: destinationPosition,
          travelMode: google.maps.TravelMode.DRIVING,
          drivingOptions: {
            departureTime: new Date(Date.now()),
            trafficModel: google.maps.TrafficModel.BEST_GUESS,
          },
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result) {
            directionsRenderer.setDirections(result);

            const leg = result.routes[0].legs[0];
            if (leg) {
              setDistance(leg.distance?.text || "N/A");
              setEstimatedTime(leg.duration?.text || "N/A");
            }
          } else {
            console.error(`Error fetching directions: ${status}`, result);
          }
        }
      );

      const trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(mapInstance);
    }
  }, [job]);

  return (
    <div
      id="map"
      className="map_style"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default MapComponent;
